/*global gsap, ScrollTrigger  */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/*global  ajax_object*/
/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}
document.addEventListener('DOMContentLoaded', function () {
  // Programmatically trigger a small scroll event to simulate user interaction
  window.scrollTo(0, 1); // Scroll by 1 pixel down
  window.scrollTo(0, 0); // Scroll back to the top
  // console.log('test');
});
/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Mega menu
   */

  $('.main-item.menu-item-has-children').mouseenter(function () {
    $('.main-item.menu-item-has-children').removeClass('show-posts');
    $(this).addClass('show-posts');
  });
  /**
   * Gallery movement
   */
  if ($('body').hasClass('home')) {
    gsap.registerPlugin(ScrollTrigger);
    const tl_second = gsap.timeline({
      scrollTrigger: {
        trigger: '.home-banner__gallery',
        start: 'top 40%',
        // end: '+=300',
        scrub: 1,
        // pin: true,
        duration: { min: 4 },
        delay: 0,
        toggleActions: 'restart none reset none',
        // markers: { startColor: 'green', endColor: 'red' },
      },
    });
    tl_second.fromTo(
      '.home-banner__gallery',
      { translateX: 0, transformOrigin: '0 100%' },
      { translateX: 100, duration: 1.95 },
      0.2
    );
  }
  /**
   * Testimonials slider
   */
  $('.testimonials__slider').slick({
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: false,
  });
  /**
   * Case Results slider
   */
  $('.case-results__slider').slick({
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  /**
   * News slider
   */
  $('.news-posts').slick({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  });
  /**
   * Blog Post slider
   */
  $('.blog-posts').slick({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  });
  /**
   * Testimonials slider
   */
  $('.reviews__slider').slick({
    dots: false,
    infinite: false,
    arrows: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  });
  /**
   * Show categories
   */
  $('.show-cat-btn').click(function () {
    $(this).toggleClass('active');
    $('.post-list__category').toggleClass('show-cat');
    if ($('.show-cat-btn').hasClass('active')) {
      $('body').addClass('no-scroll-mob');
    }
  });

  $('.close-cat-btn').click(function () {
    $('.post-list__category').removeClass('show-cat');
    $('.show-cat-btn').removeClass('active');
    $('body').removeClass('no-scroll-mob');
  });
  /**
   *  Remove filter from video
   */
  $('.iframe__wrapper').on('mouseenter', function () {
    $(this).find('iframe').css('filter', 'none');
  });
  $('.faq-item').on('mouseenter', function () {
    $(this).find('iframe').css('filter', 'none');
  });
  /**
   * Blog filtration
   */
  let postPerPage = 9;

  function loadScrollPosts(response, taxonomy = false) {
    let post = $('.ajax-posts').data('post').toLowerCase();
    let offset = taxonomy ? 0 : $('.post__wrapper').length;
    let category = $('.post-list__category').find('.active').data('category');
    let name = $('.post-list__category').find('.active').data('name');
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        action: 'loadmore',
        post: post,
        offset: offset,
        category: category,
        postperpage: postPerPage,
      },
      beforeSend: function () {
        $('.ajax-loading').hide();
        if (taxonomy) {
          // $('.ajax-loading').show();
        }
      },
      success: function (response) {
        $('.ajax-loading').hide();
        if (taxonomy) {
          $('.ajax-posts').html(response.html);
          $('.show-cat-btn__text').text(name);
          $('.post-list__category').removeClass('show-cat');
          $('.reset').css('display', 'inline-block');
          $('.show-cat-btn').removeClass('active');
          $('body').removeClass('no-scroll-mob');
        } else {
          $('.ajax-posts').append(response.html);
        }
        if ($('.blog-post__wrapper').length < response.post_count) {
          $('.ajax-loading').show();
        }
        $('.reset').click(function () {
          setTimeout(function () {
            $('.reset').css('display', 'none');
          }, 1500);
        });
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }

  $('.cat-name').click(function (e) {
    e.preventDefault();
    $('.cat-name').removeClass('active');
    $(this).addClass('active');
    loadScrollPosts('response', true);
  });

  $('.post-load').click(function () {
    loadScrollPosts();
  });
  /**
   * Header Scroll
   */
  window.onscroll = function () {
    headerScroll();
  };
  window.onload = function () {
    headerScroll();
  };
  function headerScroll() {
    if (window.pageYOffset > 0) {
      $('.header').addClass('is-scrolled');
    } else {
      $('.header').removeClass('is-scrolled');
    }
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  jarallaxElement();
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.5,
  });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('body').toggleClass('no-scroll');
      // $('.has-dropdown').append('<span class="open_submenu"></span>');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
      $('.has-dropdown').remove('<span class="open_submenu"></span>');
      $('body').removeClass('no-scroll');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
